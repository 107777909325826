import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import Metadata from '../components/metadata/metadata';
import UXTemplate from '../components/ux/ux';

const UXPage = ({ data }) => {
  const { markdownRemark: page, prev, next } = data;
  const prevExample = prev && prev.edges.length > 0 ? prev.edges[0].node : null;
  const nextExample = next && next.edges.length > 0 ? next.edges[0].node : null;

  return (
    <Layout>
      <Metadata pageData={page.frontmatter} />
      <UXTemplate
        title={page.frontmatter.title}
        content={page.htmlAst}
        previousExample={prevExample}
        nextExample={nextExample}
      />
    </Layout>
  );
};

UXPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
};

export default UXPage;

export const UXQuery = graphql`
  query($id: String!, $weight: Int!) {
    markdownRemark(id: { eq: $id }) {
      htmlAst
      frontmatter {
        title
        description
      }
    },
    prev: allMarkdownRemark(
      filter: {
        fields: { slug: { regex: "\/engineering/ux\/.+\/" }},
        frontmatter: {weight: {lt: $weight}}
      },
      sort: {fields: [frontmatter___weight], order: DESC},
      limit: 1
    ) {
      edges {
        node {
          frontmatter {
            title
            navtitle
          }
          fields {
            slug
          }
        }
      }
    },
    next: allMarkdownRemark(
      filter: {
        fields: { slug: { regex: "\/engineering/ux\/.+\/" }},
        frontmatter: {weight: {gt: $weight}}
      },
      sort: {fields: [frontmatter___weight]},
      limit: 1
    ) {
      edges {
        node {
          frontmatter {
            title
            navtitle
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
