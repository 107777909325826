import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Box, Flex } from 'rebass/styled-components';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';
import MaxWidthBox from '../max-width-box/max-width-box-css';
import Footer from '../footer/footer';
import LinkArrow from '../link-arrow/link-arrow-css';
import renderAst from '../../utils/renderAst';
import config from '../../../site-config';

const HeaderLink = styled(Link)`
  text-decoration: none;
`;

const UXTemplate = ({
  title,
  content,
  previousExample,
  nextExample,
}) => (
  <>
    <MaxWidthBox maxWidth={0} px={[1, 1, 2, 3, 4, 5]}>
      <Helmet title={`${title} - ${config.siteTitle} UX`}>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Box as="header" py={1}>
        <HeaderLink to="/engineering/ux">Ernie Bello <strong>Web Engineer</strong></HeaderLink>
      </Box>
      <h1>{title}</h1>
      {renderAst(content)}
      {(previousExample || nextExample) && (
        <Flex justifyContent="space-between" pt={1} mt={2} css={{ borderTop: 'solid 1px rgba(0, 0, 0, 0.075)' }}>
          <Box>
            {previousExample && (
              <LinkArrow to={previousExample.fields.slug}>
                <IoIosArrowRoundBack />
                {previousExample.frontmatter.navtitle || previousExample.frontmatter.title}
              </LinkArrow>
            )}
          </Box>
          <Box>
            {nextExample && (
              <LinkArrow to={nextExample.fields.slug}>
                {nextExample.frontmatter.navtitle || nextExample.frontmatter.title}
                <IoIosArrowRoundForward />
              </LinkArrow>
            )}
          </Box>
        </Flex>
      )}
    </MaxWidthBox>
    <Footer />
  </>
);

UXTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  previousExample: PropTypes.shape({
    frontmatter: PropTypes.shape({
      navtitle: PropTypes.string,
      title: PropTypes.string.isRequired,
    }),
    fields: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }),
  }),
  nextExample: PropTypes.shape({
    frontmatter: PropTypes.shape({
      navtitle: PropTypes.string,
      title: PropTypes.string.isRequired,
    }),
    fields: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }),
  }),
};

UXTemplate.defaultProps = {
  previousExample: null,
  nextExample: null,
};

export default UXTemplate;
