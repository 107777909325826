import styled from 'styled-components';
import { Link } from 'gatsby';

export default styled(Link)`
  svg {
    position: relative;
    top: 0.3em;
    font-size: 1.5em;
  }
`;
